// var jQ = jQuery.noConflict();






(function (document, navigator, CACHE, IE9TO11) {
    if (IE9TO11 || navigator.userAgent.indexOf("Edge") > -1) {
        window.SVGUseSupport = false;
        onResourcesAndDOMReady(function () {
            fixSvgIE9(document, CACHE);
        });
    }

})(
    document,
    navigator,
    {},
    /Trident\/[567]\b/.test(navigator.userAgent)
);


if (typeof $ !== "function") {
    window.$ = function (id) {
        return document.getElementById(id);
    };

}


/**
 *
 * Adapter END
 *
 */
// jQuery('body').on('click', '*[amd-action]', function (event) {
//     console.log('RETURN FALSE');
//     return false;
// });

/**
 *
 * Cut string to lines START
 *
 */


let measureCanvas = null;
let measureCanvasContext = null;

(function( jQ ) {
    var didEndResizeTimer = 0;
    var didEndResizeFunction = false;

    jQ(document).on('click', '*[amd-action]', function (event) {
        return false;
    });

    jQ.fn.cutTextToLines = function() {
        // return; // test-optimisation
        if(typeof getComputedStyle != 'function'){
            return false;
        }

        // if (!document.getElementById('ruler')) {
        //     var rulerSpan = document.createElement('span');
        //     rulerSpan.setAttribute("id", "ruler");
        //     rulerSpan.style.display = "hidden";
        //     rulerSpan.style.position = "absolute";
        //     rulerSpan.style.top = "-9999px";
        //     rulerSpan.style.left = "-9999px";
        //     rulerSpan.style.whiteSpace = "nowrap";
        //     document.body.appendChild(rulerSpan);
        // }

        this.each(function(index, elem){
            setTimeout(function () {
            //     if (!jQ(elem).is("[original-text]")) {
            //         jQ(elem).attr('original-text', jQ(elem).text().trim());
            //     }
                if (!elem.hasAttribute('original-text')) {
                    elem.setAttribute('original-text', elem.innerText.trim());
                }

                jQ(elem).trimToLines();
            }, 0);

            // var maxHeight = jQ(elem).css('max-height');
            // var linesCount;
            // if (maxHeight == '99999px') {
            // 	linesCount = 30;
            // } else if (!jQ(elem).is("[lines-count]")) {
            // 	var computedStyle = getComputedStyle(elem);
            // 	linesCount = Math.floor(computedStyle.getPropertyValue("height").replace('px', '') / computedStyle.lineHeight.replace('px', ''));
            // } else {
            // 	linesCount = jQ(elem).attr('lines-count');
            // }
            // jQ(elem).trimToLines(linesCount);

        });

        if (!didEndResizeFunction) {
            didEndResizeFunction = function () {
                clearTimeout(didEndResizeTimer);
                didEndResizeTimer = setTimeout(function () {
                    jQ('.cutText').cutTextToLines();
                }, 200);
            };
            jQ(window).bind('resize', didEndResizeFunction);
            setTimeout(didEndResizeFunction, 300);
        }

        return this;
    };

    // jQ.fn.visualLength = function(text) {
    //    if(typeof getComputedStyle != 'function'){
    //        return false;
    //    }
    //
    // 	var element = jQ(this).get(0);
    // 	var ruler = document.getElementById('ruler');
    // 	if (element) {
    // 		var computedStyle = getComputedStyle(element);
    // 		ruler.style.fontSize = computedStyle.fontSize;
    // 		ruler.style.fontWeight = computedStyle.fontWeight;
    // 		ruler.style.fontFamily = computedStyle.fontFamily;
    // 		ruler.style.textTransform = computedStyle.textTransform;
    // 	}
    // 	ruler.innerHTML = text;
    // 	return ruler.offsetWidth;
    // };

    jQ.fn.trimToLines = function() {

        var element = jQ(this).get(0);
        const styles = window.getComputedStyle(element);

        var moreLink = jQ(element).next('a').get(0);
        if (!moreLink) {
            moreLink = jQ(element).find('a').get(0);
        }
        var moreLinkText = '';
        if (moreLink) {
            moreLinkText = ' ' + moreLink.innerText;
        }

        if (!measureCanvas) {
            measureCanvas = document.createElement('canvas');
        }
        if (!measureCanvasContext) {
            measureCanvasContext = measureCanvas.getContext('2d');
        }
        measureCanvasContext.font = styles.font;

        const linesCount = Math.round(parseFloat(styles.maxHeight) / parseFloat(styles.lineHeight));

        const width = element.clientWidth - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight);

        var text = element.getAttribute('original-text');

        let textWidth = measureCanvasContext.measureText(text + moreLinkText).width;

        if (textWidth > width) {

            let lines = [];
            let words = text.split(" ");

            for (let line = 0; line < linesCount; line++) {
                let string = '';

                while (words.length) {
                    let word = words.shift();
                    let newString = string + ' ' + word;
                    newString = newString.trim();

                    let measureText = newString;
                    // "... more" is added only to last line.
                    if (line + 1 >= linesCount) {
                        measureText = newString + '...' + moreLinkText;
                    }
                    const newWidth = measureCanvasContext.measureText(measureText).width;

                    if (newWidth > width) {
                        // Add word back if not used.
                        words.unshift(word);
                        break;
                    }
                    string = newString;

                }
                lines.push(string);
            }

            const truncatedString = lines.join(' ') + '... ';

            element.innerHTML = truncatedString;
        }

        if (moreLink) {
            element.appendChild(moreLink);
            moreLink.style.display = '';
        }


        return this;

        // function cutLastWord(string) {
        //     string = string.trim();
        //     string = string.substring(0, string.lastIndexOf(" "));
        //     return string;
        // }
        //
        // var element = jQ(this).get(0);
        // var text = jQ(element).attr('original-text');
        // if (!text.length) {
        //     jQ(element).hide();
        //     return this;
        // }
        //
        // // jQ(element).after('<div class="width-ruller"></div>');
        // // jQ(element).next().css({
        // //     'padding-left': jQ(element).css('padding-left'),
        // //     'padding-right': jQ(element).css('padding-right'),
        // //     'margin-left': jQ(element).css('margin-left'),
        // //     'margin-right': jQ(element).css('margin-right'),
        // // });
        // // var elementWidth = jQ(element).next().width();//computedStyle.getPropertyValue("width").replace('px', '');
        // //
        // // if(!elementWidth){
        // //     return false;
        // // }
        //
        // // jQ(element).next().remove();
        //
        // var moreLink = jQ(element).next('a');
        //
        // if (jQ(moreLink).length) {
        //     jQ(moreLink).hide();
        //     // element.innerHTML+='&nbsp;';
        //     element.innerHTML = element.innerHTML.trim() + '&nbsp;' + jQ(moreLink).clone().show().get(0).outerHTML;
        //
        //     element.innerHTML = element.innerHTML.replace(/\r?\n|\r/g, '');
        //     // console.log(element.innerHTML);
        //     // jQ(moreLink).clone().show().appendTo(jQ(element));
        // }
        //
        // // var textParts = text.split(' ');
        // // textParts = textParts.filter(function(e){ return e.replace(/(\r\n|\n|\r)/gm,"")});
        // element.innerHTML = text;
        // var newText = text;
        //
        // // for (var i=0; i<linesCount; i++) {
        // // 	var line = '';
        // // 	do {
        // // 		var part = textParts.shift();
        // // 		if (!part) {
        // // 			break;
        // // 		}
        // // 		line += part + ' ';
        // // 		var lineToTest = line + textParts[0] + ' ';
        // // 	} while(jQ(element).visualLength(lineToTest) < elementWidth);
        // //
        // // 	if (i >= (linesCount - 1)) {
        // // 		if (textParts.length) {
        // //            line = line.trim();
        // //            line = line.replace(/\.+$/, "");
        // //            line = line + "...";
        // //            if (jQ(moreLink).length) {
        // //                elementWidth = elementWidth - jQ(element).find('a').width()-10;
        // //            }
        // //
        // //            var tmp_stop_index = 0;
        // // 			while (elementWidth && jQ(element).visualLength(line) >= elementWidth) {
        // // 				line = cutLastWord(line);
        // //                line = line.trim();
        // //                line = line.replace(/\.+$/, "");
        // // 				line = line + "...";
        // // 			}
        // // 		}
        // // 	}
        // // 	newText += line;
        // // }
        //
        // var i = 0;
        //
        // var element_height = parseFloat(getComputedStyle(element, null).height.replace("px", ""));
        //
        // while( element_height < element.scrollHeight) {
        //     newText = cutLastWord(newText);
        //     newText.replace(/\.+$/, "");
        //     newText = newText + "...";
        //     element.innerHTML = newText;
        //
        //     if (jQ(moreLink).length) {
        //         element.innerHTML = element.innerHTML.trim() + '&nbsp;' + jQ(moreLink).clone().show().get(0).outerHTML;
        //
        //         element.innerHTML = element.innerHTML.replace(/\r?\n|\r/g, '');
        //     }
        //     i++;
        //     if (i> 50) {
        //         break;
        //     }
        // }
        //
        // element.innerHTML = newText;
        // if (jQ(moreLink).length) {
        //     element.innerHTML = element.innerHTML.trim() + '&nbsp;' + jQ(moreLink).clone().show().get(0).outerHTML;
        //
        //     element.innerHTML = element.innerHTML.replace(/\r?\n|\r/g, '');
        // }
        // return this;
    };
}( jQuery ));
/**
 *
 * Cut string to lines END
 *
 */

//open school select






/**
 * Conversion Tracking
 */
// var ConversionTracking_process_timer = null;



if (typeof(plugin_data) === 'undefined'){
    onResourcesAndDOMReady(function(){
        var CT = new ConversionTracking();
        CT.setupHandlers();
        CT.process();
    });
}

/**
 * Conversion Tracking END
 */




if( typeof(plugin_data) !== 'undefined' && typeof(plugin_data.uloop_category_slug) !== 'undefined' && plugin_data.uloop_category_slug == 'profile' ){
    var css = '#footer-uloop-widget, #footer-info {display: none !important;}',
        head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('style');
    style.type = 'text/css';
    if (style.styleSheet) {
        style.styleSheet.cssText = css;
    } else {
        style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
    console.log('try hide');
}




/**
 *
 * Cross domain storage START
 *
 */

//Initialize plugin JS
window.CodeAdapter.initWordpressPlugin();



////example
//CDR().request({
//    url: 'http://ucsb.uloop.loc/index.php',
//    data: 'test-cdr=true',
//    dataType: 'html',
//    success: function(data){
//	//console.log(data);
//    }
//});

/*
 *
 * Crossdomain requests END
 *
 */

if (window.CodeAdapter.project_type === 'wordpress_plugin') {
    crossDomainRequest.init(getCookie(window.session_name));

    AJAXBaseURL = plugin_data.uloop_base_domain;
    AJAXSessionParam = '&PHPSESSID=' + getCookie(window.session_name) + '&' + window.CodeAdapter.plugin_param;
}

/*
 *
 * Login
 *
 */




onResourcesAndDOMReady(function () {

    // var search_location_value = '';
    // var search_location_timer = false;

    // var location_search_xhr = false;
    // var search_xhr = false;
    // var location_change = '';
    // var mlsd_type = '';
    // var mlsd_id = '';
    // var map_view_custom_draw = false;
    // var map_view_zoom = false;
    // var MainPage = jQuery("html, body", window.MainWindowDocument);
    // var DomainView = false;

    jQ('.sitemap-school-link').click(function(){

        if (jQ(this).closest('li').find('.school-categories').hasClass('active')) {
            return true;
        } else {
            jQ('.school-categories.active').slideUp();
            jQ(this).closest('li').find('.school-categories').slideDown();
            jQ(this).closest('li').find('.school-categories').addClass('active');
        }

        return false;
    });


    if(!('placeholder' in document.createElement('input'))){
        fixPlaceholders();
        fixPlaceholdersAdditional();
    }

    jQ('.cutText').cutTextToLines();

    jQ('body').on('click', '.list-row-clickable', function (e) {

        if (jQ(e.target).closest('.ignore-click-redirect').length ||
            jQ(e.target).hasClass('ignore-click-redirect') ||
            jQ(e.target).is('a') ||
            jQ(e.target).closest('a').length
        ) {
            console.log('not redirect');
        } else {
            var link = jQ(this).attr('href');
            if (link.indexOf('view_external_link') !== -1) {
                window.open(jQ(this).attr('href'), '_blank');
            } else {
                window.location.href = jQ(this).attr('href');
            }

            // window.location.href = jQ(this).attr('href');
        }
    });

    if(window.CurrentUser.getId()){
        window.Posting_Login_User = true;
        showLoggedUserMenu();
    }
    else {
        showLoginMenu();
    }

    processTrackClickAction();

    jQ("body").on('click', '*[click-action]', function () {
        trackClickAction(this);
    });

    processWidgetsJsonUloop();

    /******* INIT SOCKET *****/
    if (typeof(io) === 'function') {
        initJsSocket();
    }

    jQ("body").on('mousedown', "#right_content .right_links_item a, " +
        "#right_content .right_content_list a, " +
        "#right_content .sidebar_link, " +
        "#right_content .textbook a, " +
        "#right_content .post_sidebar_link", function () {

        var data_index = jQ(this).attr('data-index');

        if (typeof data_index !== typeof undefined && data_index !== false) {
            jQ(this).attr('data', data_index).removeAttr('data-index');
            processElemetAttributesFromJsonUloop(this);
        }

        var ei = jQ(this).attr('ei') ? jQ(this).attr('ei') : 1
        if (jQ(this).hasClass('post_sidebar_link')) {
            ei = 17;
        } else if (jQ(this).text().indexOf('See all') != -1) {
            ei = 15;
        }

        var wi = jQ(this).attr('wi') ? jQ(this).attr('wi') : 0,
            fp = window.location.pathname,
            tp = jQ(this).attr('href'),
            ci = jQ(this).attr('ci'),
            pb = jQ(this).attr('pb'),
            chi = jQ(this).attr('chi'),
            sbi = jQ(this).attr('sbi');

        trackUloopEvent(ei, fp, tp, ci, pb, chi, sbi, wi);

    });


    jQ('body').on('keyup', "#ask_question textarea", function(){
        var q_length = parseInt(jQ(this).val().length);
        var form = jQ(this).parents('#ask_question');

        if(q_length <= 300){
            jQ("#character-counter", form).html("");
        } else{
            jQ("#character-counter", form).html(300 - q_length);
        }
    });


    jQ(document).on('GalleryViewModeUpdatedEvent', function() {
        jQ(window).resize(function () {
            jQ('.post-gallery-wrap .post-gallery').removeClass('nth-child-4n-plus-1').removeClass('nth-child-3n-plus-1').removeClass('nth-child-2n-plus-1');
            if(window.m_w_width <= 985 && window.m_w_width >= 661){
                jQ('.post-gallery-wrap .post-gallery:nth-child(4n+1)').addClass('nth-child-4n-plus-1');
            } else if(window.m_w_width <= 460){
                jQ('.post-gallery-wrap .post-gallery:nth-child(2n+1)').addClass('nth-child-2n-plus-1');
            } else if(window.m_w_width <= 320){

            } else {
                jQ('.post-gallery-wrap .post-gallery:nth-child(3n+1)').addClass('nth-child-3n-plus-1');
            }
        });

        jQ('.post-gallery-wrap .post-gallery').removeClass('nth-child-4n-plus-1').removeClass('nth-child-3n-plus-1').removeClass('nth-child-2n-plus-1');
        if(window.m_w_width <= 985 && window.m_w_width >= 661){
            jQ('.post-gallery-wrap .post-gallery:nth-child(4n+1)').addClass('nth-child-4n-plus-1');
        } else if(window.m_w_width <= 460){
            jQ('.post-gallery-wrap .post-gallery:nth-child(2n+1)').addClass('nth-child-2n-plus-1');
        } else if(window.m_w_width <= 320){

        } else {
            jQ('.post-gallery-wrap .post-gallery:nth-child(3n+1)').addClass('nth-child-3n-plus-1');
        }
    });

    if(jQ('.post-gallery').length){
        jQ(document).trigger('GalleryViewModeUpdatedEvent');
    }


    if (jQ('.the-loop-widget')) {

        if(jQ('.the_loop_page')) {
            jQ('.the-loop-widget').height(jQ('.the-loop').height())
        }

        var i = 0;
        window.setInterval(function(){
            if (i == 20) i = 0;
            jQ(".post-row[index=\"" + i + "\"]:eq(0)").clone().prependTo(".sidebar-posts-hidden-scroll").addClass('highlight-row');
            i++;
        }, 3000);
    }

});


/*
 onResourcesAndDOMReady(function(){
 Iphone.IphoneStart({navigation: true});
 });

 jQ('.popup_overlay, .loading').fadeIn(500);

 onResourcesAndDOMReady(function(){
 //window.onload(function(){
 jQ('.popup_overlay, .loading').fadeOut(500);
 });

 */


//SHOULD be rewritten for API mode



onResourcesAndDOMReady(function () {

    window.MainWindowDocument.location_change = true;

    checkFlexWrapSupport();

    if (window.CodeAdapter.project_type == 'wordpress_plugin') {
        CrossStorage = new window.CrossDomainStorage({
            origin: document.location.protocol + "//" + document.location.host,
            path: '',
            sync: true
        });
    }
    else if (typeof(COOKIE_DOMAIN) !== 'undefined' && COOKIE_DOMAIN !== null) {

        if (typeof(window.mobileApp) === 'undefined' || !window.mobileApp) {

            CrossStorage = new window.CrossDomainStorage({
                origin: (window.location.host.indexOf(".loc") !== -1 || window.location.host.indexOf("uloopdemo") !== -1 ? window.location.protocol : 'https:') + "//www" + COOKIE_DOMAIN,
                path: (window.location.protocol != 'https:' ? '/CrossStorageUloop.html' : ''),
                sync: true
            });

            CheckFavorite();
            CheckFavoriteIndex();
            DetailCheckFavorite();
        }
    }

    jQ(document).on('FindScrollIndicatorEvent', function() {
        window.index_uloop_current_page = window.index_uloop_current_page || 1;
        window.index_uloop_can_load_next_page =  window.index_uloop_can_load_next_page || true;
        jQ(window).scroll(function () {
            if (isInViewport(jQuery('#index-scroll-indicator').get(0))) {

                jQ('.the_loop_page').length ? the_loop_load_next_page() : index_load_next_page();
            }
        });
    });

    if(jQuery('#index-scroll-indicator').get(0)){
        jQ(document).trigger('FindScrollIndicatorEvent');
    }

    jQ('.select-to-boxlist').selectToBoxlist();

    jQ('.custom-select').customSelect();

    // tabs
    jQ("body").on("click", ".tabs-menu li", function (e) {
        var self = jQ(this),
            tab = self.attr("tab"),
            identifier = tab.replace(/[^\d]+/, "");

        if (jQ(self).hasClass('load')) {
            return false;
        }

        self.addClass("current");
        self.siblings().removeClass("current");
        jQ("#tabs-container-" + identifier + " .tab-content").not(tab).css("display", "none");
        jQ(tab).fadeIn();
    });


    jQ('body').on("click", '#detail_main_image_wrap a[data-type="video"], .wrap_video_in_description a[data-type="video"]', function () {
        var elem = jQ(this);

        if (typeof(jQ(elem).attr('data')) != 'undefined' && jQ(elem).attr('data').length) {
            jQ(':before', elem).hide();
            jQ(elem).html('<iframe src="' + jQ(elem).attr('data') + '" style="display: block; max-width: 595px; margin: 0px auto; max-height: 300px; width: 595px; height: 300px;" frameborder="0"></iframe>');
            jQ('iframe', elem).fadeIn(300);
        }
    });

    jQ('body').on("click", '.drop-down', function () {
        var elem = jQ(this);
        if (jQ('.drop-down-hidden', elem).is(":visible")) {
            jQ(elem).removeClass('opened closed').addClass('closed');
            jQ('.drop-down-hidden', elem).slideUp(300);
        } else {
            jQ(elem).removeClass('opened closed').addClass('opened');
            jQ('.drop-down-hidden', elem).slideDown(300);
        }
    });


    window.CodeAdapter.wordpressDocumentReady();

    jQ('body').on("submit", ".location_main_search form", function (event) {

        event.preventDefault();

        if (!jQ("input[name='location']", this).val().length) {
            CMPS_SetErrorText($('location_input-error'), ' Please enter a valid College');
            $('location_input').focus();
            addCustomEvent('location_input', '', 'keydown');
        }
        else {
            var preloader = jQ(this).find('.s_preload');
            jQ(preloader).fadeIn(300);

            window.CodeAdapter.ajaxRequest({
                url: "index.php",
                data: jQ(this).serialize(),
                dataType: "html",
                type: "POST",
                cache: "false",
                success: function (data) {
                    if (data.length) {
                        window.location = data + '/' + window.location.pathname.substring(1);
                    }
                    else {
                        jQ(preloader).fadeOut(300);
                        CMPS_SetErrorText($('location_input-error'), ' Please enter a valid College');
                        $('location_input').focus();
                        addCustomEvent('location_input', '', 'keydown');
                    }
                }
            });
        }
    });

    jQ('body').on('keyup', 'form[name="location_search"] .form-input:not(.email), #location_input, #user-hometown, #user-college, .nationwide-form input[name="location"]', function (event) {
        if (event.keyCode !== 13 && event.keyCode !== 40 && event.keyCode !== 38 && event.keyCode !== 37 && event.keyCode !== 39) {

            // Hide Dorms select if cleared College input
            if (jQ(this).parents('.find-your-college-wrap').siblings('.find-your-dorms-wrap').find('input[name="location_id"]').length && event.keyCode == 8) {
                if (!jQ('.find-your-dorms-wrap').hasClass("in-active")) {
                    jQ('.find-your-dorms-wrap').addClass("in-active");
                    jQ('.find-your-dorms-wrap').find('input[name="scholarship_search"]').val('');
                }
            }

            StartProcessLocationSearch(this);
        }
    });

    // Open Dorms Dropdown List at Scholarships Survey
    // jQ('body').on('focus', '.find-your-dorms-wrap form[name="location_search"] .form-input:not(.email)', function (event) {
    //     if (event.keyCode !== 13 && event.keyCode !== 40 && event.keyCode !== 38 && event.keyCode !== 37 && event.keyCode !== 39) {
    //         StartProcessLocationSearch(this);
    //     }
    // });

    jQ('body').on("change", ".location_main_search input[name='location_url'], .change-location input[name='location_url']", function () {
        var form = jQ(this).parents('form');

        // Do not relocate if Scholarship or Sign Up Survey
        if (jQ(".uloop-scholarships-survey").length || jQ(".sign-up-simple").length) {
            return false;
        }

        if (jQ('.filter-find-location').length) {
            AdminStatisticPageSearch();
            return false;
        }

        window.location = jQ("input[name='location_url']", form).val() + '/' + window.location.pathname.substring(1);
    });

    jQ('body').on('keydown', 'form[name="location_search"] .form-input:not(.email), #location_input, #user-hometown, #user-college, .nationwide-form input[name="location"]', function (event) {
        window.MainWindowDocument.location_change = true;

        if (event.keyCode !== 13) {
            jQ('#housing-search-form').attr('action', '/search');
        }
        if (event.keyCode === 13 || event.keyCode === 40 || event.keyCode === 38) {
            var total_loc_idx = jQ('body').find('.fast_search_location_results .fast_location_item').length;

            // enter
            if (event.keyCode === 13) {
                if (jQ('.fast_search_location_results .fast_location_search_focus_item').length) {
                    jQ('.fast_search_location_results .fast_location_search_focus_item').click();
                }
                else {
                    jQ('#search_location_btn').removeClass('active');
                }
            }
            // down
            else if (event.keyCode === 40) {
                jQ('.fast_search_location_results .fast_location_item').removeClass('fast_location_search_focus_item');

                window.search_location_row_index++;

                if (window.search_location_row_index === total_loc_idx) {
                    window.search_location_row_index = 0;
                }

                var FocusRow = jQ('.fast_location_item:eq(' + window.search_location_row_index + ')');
                jQ(FocusRow).addClass('fast_location_search_focus_item');


                if (jQ(FocusRow).length)
                    jQ('#location_container .fast_search_location_results').scrollTo('.fast_location_search_focus_item');

            }
            // up
            else if (event.keyCode === 38) {
                jQ('.fast_search_location_results .fast_location_item').removeClass('fast_location_search_focus_item');

                window.search_location_row_index--;

                if (window.search_location_row_index === -1) {
                    window.search_location_row_index = total_loc_idx - 1;
                }

                var FocusRow = jQ('.fast_location_item:eq(' + window.search_location_row_index + ')');
                jQ(FocusRow).addClass('fast_location_search_focus_item');

                if (jQ(FocusRow).length)
                    jQ('#location_container .fast_search_location_results').scrollTo('.fast_location_search_focus_item');
            }

            return false;
        }
        // esc if (event.keyCode === 27)
        else {
            jQ('#header_filters input[name="location_type"]', window.MainWindowDocument).val('');
            jQ('#header_filters input[name="location_value"]', window.MainWindowDocument).val('');
            jQ('#fast_search_results .fast_search_item').removeClass('fast_search_focus_item');
            window.search_location_row_index = -1;
        }
    });

    jQ('.tutors_categories input[name="online"]').change(function (event) {
        runTutorSearch(jQ(this).parents('form').get(0), event);
    });


    jQ("#popup_location_input").keyup(function (event) {
        if (event.keyCode !== 13 && event.keyCode !== 40 && event.keyCode !== 38 && event.keyCode !== 37 && event.keyCode !== 39) {
            StartProcessPopupLocationSearch(this);
        }

    });

    jQ('#scroll_top').click(function (event) {
        jQ('html, body').stop().animate({
            scrollTop: 0
        }, 400);
    });

    jQ('#popup_location_input').keydown(function (event) {

        window.MainWindowDocument.location_change = true;

        if (event.keyCode !== 13) {
            jQ('#housing-search-form').attr('action', '/search');
        }
        if (event.keyCode === 13 || event.keyCode === 40 || event.keyCode === 38) {
            var total_loc_idx = jQ('body').find('.popup_fast_search_location_results .popup_fast_location_item').length;

            // enter
            if (event.keyCode === 13) {
                if (jQ('.popup_fast_search_location_results .popup_fast_location_search_focus_item').length) {
                    jQ('.popup_fast_search_location_results .popup_fast_location_search_focus_item').click();
                }
                else {
                    jQ('#search_location_btn').removeClass('active');
                }
            }
            // down
            else if (event.keyCode === 40) {
                jQ('.popup_fast_search_location_results .popup_fast_location_item').removeClass('popup_fast_location_search_focus_item');

                window.search_location_row_index++;

                if (window.search_location_row_index === total_loc_idx) {
                    window.search_location_row_index = 0;
                }

                var FocusRow = jQ('.popup_fast_location_item:eq(' + window.search_location_row_index + ')');
                jQ(FocusRow).addClass('popup_fast_location_search_focus_item');


                if (jQ(FocusRow).length)
                    jQ('#popup_location_container .popup_fast_search_location_results').scrollTo('.popup_fast_location_search_focus_item');

            }
            // up
            else if (event.keyCode === 38) {
                jQ('.popup_fast_search_location_results .popup_fast_location_item').removeClass('popup_fast_location_search_focus_item');

                window.search_location_row_index--;

                if (window.search_location_row_index === -1) {
                    window.search_location_row_index = total_loc_idx - 1;
                }

                var FocusRow = jQ('.popup_fast_location_item:eq(' + window.search_location_row_index + ')');
                jQ(FocusRow).addClass('popup_fast_location_search_focus_item');

                if (jQ(FocusRow).length)
                    jQ('#popup_location_container .popup_fast_search_location_results').scrollTo('.popup_fast_location_search_focus_item');
            }

            return false;
        }
        // esc if (event.keyCode === 27)
        else {
            jQ('#header_filters input[name="location_type"]', window.MainWindowDocument).val('');
            jQ('#header_filters input[name="location_value"]', window.MainWindowDocument).val('');
            jQ('#popup_fast_search_results .popup_fast_search_item').removeClass('popup_fast_search_focus_item');
            window.search_location_row_index = -1;
        }
    });

    jQ('body').on('click', 'ul.sallie_ul li', function () {
        var currentElem = this;
        var hideShowElem = jQ('p', currentElem);
        if (jQ(hideShowElem).css('display') === 'none') {
            jQ(hideShowElem).slideDown('fast');
            jQ(currentElem).css('background', 'url(' + window.imagesDomain + '/img/salliemae/chh_blue_arrow_down.gif) no-repeat left 5px');
        }
        else {
            jQ(hideShowElem).slideUp('fast');
            jQ(currentElem).css('background', 'url(' + window.imagesDomain + '/img/salliemae/li_footer.gif) no-repeat left 8px');
        }
    });

    if (navigator.userAgent.indexOf("Firefox") > -1 || navigator.userAgent.indexOf('MSIE') > -1) {
        jQ('.news_list .item .wrap_ellipsis').cutText({
            size: 50
        });

        jQ('.news_list_2 .item .wrap_ellipsis').cutText({
            size: 55
        });
    }

//    $clamp(jQ( ".news_list .item .wrap_ellipsis").get(0), {clamp: 1});

// Mobile Safari in standalone mode
    if (("standalone" in window.navigator) && window.navigator.standalone) {

        window.iphone = true;

        Iphone.IphoneStart();

        document.addEventListener('click', function (event) {

            var noddy = event.target;

            // Bubble up until we hit link or top HTML element. Warning: BODY element is not compulsory so better to stop on HTML
            while (noddy.nodeName !== "A" && noddy.nodeName !== "HTML") {
                noddy = noddy.parentNode;
            }

            if ('href' in noddy && noddy.href.indexOf('http') !== -1 && noddy.host.indexOf('uloop.com') !== -1) {
                event.preventDefault();
                document.location.href = noddy.href;
            }

        }, false);

    }

    jQ('body').on("click", '.hotlist', function (e) {

        if (window.CodeAdapter.project_type == 'wordpress_plugin') {
            var listing_id = jQ(this).attr('listing_id');
            var parent_id = jQ(this).attr('parent_id');
            if (typeof(listing_id) === 'undefined') {
                listing_id = parent_id;
            }
            var listing_type = ~~jQ(this).attr('listing_type');
            var listing_category = ~~jQ(this).attr('listing_c');
            var listing_subcategory = ~~jQ(this).attr('listing_s');

            if (!window.CurrentUser.getId()) { ///student/student-sign-up
                if (!jQ('#sgn_in_for_save_hotlist_popup').legth) {
                    var hotlist_login_popup = jQ('#sgn_in_popup').clone().attr('id', 'sgn_in_for_save_hotlist_popup');
                    jQ('form', hotlist_login_popup).attr('id', 'hotlist_login');
                    jQ('h3', hotlist_login_popup).removeClass('float_l').html('');
                    jQ('.login-sign-up-link, .vertical_align, h4', hotlist_login_popup).remove();
                    jQ('#sgn_in_popup').after(hotlist_login_popup);
                    if (!jQ('#sgn_in_for_save_hotlist_popup').has('.popup_close_button')) {
                        jQ('#sgn_in_for_save_hotlist_popup .sgn_in_popup').after('<span class="popup_close_button"></span>');
                    }
                    jQ('#sgn_in_for_save_hotlist_popup .popup_close_button').click(function () {
                        closePopup('sgn_in_for_save_hotlist_popup')
                    });
                }

                var sign_up_link = jQ('.login-sign-up-link.default a').attr('href');

                if (
                    page_type.type !== 'homepage' &&
                    page_type.type !== 'news_category' &&
                    page_type.type !== 'university_homepage' &&
                    page_type.type !== 'news_detail'
                ) {

                    jQ('#sgn_in_for_save_hotlist_popup h3').html('Log In to Save a Listing<br/><span>Need to Sign Up? <a href="' + sign_up_link + '" onclick="ShowSignUpPopup(event);">Click Here</a></span>');
                } else {
                    jQ('#sgn_in_for_save_hotlist_popup h3').html('Log In to Save an Article<br/><span>Need to Sign Up? <a href="' + sign_up_link + '" onclick="ShowSignUpPopup(event);">Click Here</a></span>');
                }


                var popup_id = "sgn_in_for_save_hotlist_popup";
                popup
                ({
                    popup_id: popup_id,
                    speed: 100
                });

                return false;
            }
        }
        else {
            var listing_id = ~~jQ(this).attr('listing_id');
            var parent_id = ~~jQ(this).attr('parent_id');
            var listing_type = ~~jQ(this).attr('type');
            var listing_category = ~~jQ(this).attr('listing_c');
            var listing_subcategory = ~~jQ(this).attr('listing_s');
        }

        //delete hotlist item
        if (jQ(this).hasClass('added')) {

            window.CodeAdapter.ajaxRequest({
                url: "ajaxapi.php",
                data: 'hotlist_action=1&listing_id=' + listing_id + '&parent_id=' + parent_id + '&type=' + listing_type + '&listing_c=' + listing_category + '&listing_s=' + listing_subcategory + '&action=removed',
                type: "POST",
                success: function (date) {
                }
            });

            RemoveItemFromHotlist(listing_type, listing_id, parent_id);

            jQ(this).html('<svg class="favorites-icon ignore-click-redirect"><use xlink:href="/img/svg/uloop.svg#star"></use></svg>');
            fixSvgIE9(document, {});
            jQ(this).addClass('deleted');
            jQ(this).removeClass('added');
            jQ(this).attr('title', "Add to Favorites");

            if ((window.CodeAdapter.project_type == 'wordpress_plugin' && jQ(this).hasClass('profile') ) || window.location.pathname.indexOf('/favorites') === 0) {
                FadeOutHotlistElementWithoutDelete(this, 300);
//                FadeOutHotlistElement(this,300);
//                setTimeout(RemoveHotlistElement,300,this);
            }

//            jQ.cookie("hotlistArray",JSON.stringify(hotlistArray),{expires: 365,path: '/',domain:COOKIE_DOMAIN});

            //add hotlist item
        } else {


            window.CodeAdapter.ajaxRequest({
                url: "ajaxapi.php",
                data: 'hotlist_action=1&listing_id=' + listing_id + '&parent_id=' + parent_id + '&type=' + listing_type + '&listing_c=' + listing_category + '&listing_s=' + listing_subcategory + '&action=added',
                type: "POST",
                success: function (date) {
                }
            });


            AddItemToHotlist(listing_type, listing_id, parent_id);

            jQ(this).html('<svg class="favorites-icon ignore-click-redirect"><use xlink:href="/img/svg/uloop.svg#star-filled"></use></svg>');
            fixSvgIE9(document, {});
            jQ(this).addClass('added');
            jQ(this).removeClass('deleted');
            jQ(this).attr('title', 'Remove from Favorites');

            if ((window.CodeAdapter.project_type == 'wordpress_plugin' && jQ(this).hasClass('profile') ) || window.location.pathname.indexOf('/favorites') === 0) {
                FadeInHotlistElementWithoutDelete(this, 300);

            }

//            jQ.cookie("hotlistArray",JSON.stringify(hotlistArray),{expires: 365,path: '/',domain:COOKIE_DOMAIN});

        }

    });

    if(jQ('.view-switch').length) {
        window.uloop_current_view_mode = jQ('.view-switch input[name="view-mode"]:checked').val();
        jQ('.view-switch input[name="view-mode"]').change(function () {
            ChangePageViewMode(jQ('.view-switch input[name="view-mode"]:checked').val());
        });
    }


    //textbooks buttons tracking
    jQ('.professors_detail .add-to-cart-button,.professors .add-to-cart-button').on('mousedown', function () {

        if (window.CodeAdapter.project_type === 'wordpress_plugin') {
            return;
        }

        var arr = jQ(this).text().split(' ');
        var button_type = arr[0];
        var element_id = 0;

        var channel_id = 300; //textbooks
        var powered_by = 490; //chegg_textbooks

        switch (button_type) {
            case "Sell":
                element_id = 15;
                break;
            case "Buy":
                element_id = 16;
                break;
            case "Rent":
                element_id = 17;
                break;
        }

        trackUloopClick('tracking_textbooks_btn_click', element_id, window.location.pathname, jQ(this).parent().attr('href'), powered_by, channel_id);
    });

    jQuery('a.hidden-tracking').bind("contextmenu", function (e) {
        return false;
    });
    jQuery('a.hidden-tracking').click(function (event) {
        var elem = this;

        if (typeof(plugin_data) !== 'undefined' && typeof(jQuery(elem).attr('view-external-link')) !== 'undefined') {
            jQuery("body").append('<iframe src="' + plugin_data.uloop_base_domain + '/view_external_link.php?' + jQuery(elem).attr('view-external-link') + '" frameborder="none" height="0" width="0"></iframe>');
        } else if (typeof(jQuery(elem).attr('view_external_link')) !== 'undefined') {
            jQuery("body").append('<iframe src="/view_external_link.php?' + jQuery(elem).attr('view_external_link') + '" frameborder="none" height="0" width="0"></iframe>');
        } else {
            console.log('Wrong tracking data!');
        }
    });


    if (!('placeholder' in document.createElement('input'))) {
        fixPlaceholders();
        fixPlaceholdersAdditional();
    }

    MarkAnswerVoteButtonsOnPageLoad();

    MarkQuestionFollowButtonsOnPageLoad();

    resizeSearchBar();

    jQ(window).resize(resizeSearchBar);

    if (!detectIE()) {
        try {
            document.execCommand('copy');
        } catch (err) {
            jQ('.qa-share-menu-content li:last').hide();
        }
        if (!document.queryCommandSupported('copy')) {
            jQ('.qa-share-menu-content li:last').hide();
        }
    } else {
        jQ('.qa-share-menu-content li:last').hide();
    }
});



(function (jQ) {

    var Iphone = function (options) {
        this.defaults = {
            navigation: true,
            preloader: true,
            resize: true
        };
        jQ.extend(true, this.defaults, options);
    };

    Iphone.prototype = {
        IphoneStart: function (options) {
            var self = this;
            this.options = {};
            var options = this.options = jQ.extend(true, this.defaults, options);

            // Add Navigation
            if (options.navigation) {
                self.navigation = self.navigation(options);
            }

            if (options.preloader) {
                self.preloader = self.preloader(options);
            }

        },


        navigation: function (options) {

            jQ('#iphone-nav-left').on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                window.history.back();
            });


            jQ('#iphone-nav-refresh').on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                window.location.reload();
            });

            jQ('#iphone-nav').show();

        },
        preloader: function (options) {

            jQ('.popup_overlay, .loading').fadeIn();

            onResourcesAndDOMReady(function () {
                jQ('.popup_overlay, .loading').fadeOut(500);
            });
        }


    };

    window.Iphone = new Iphone();

})(jQuery);


jQ(window).resize(function () {
// top menu responsive tabs
    window.m_w_width = jQ(window).width();
    if (window.m_w_width > 969) {
        jQ('.main_menu_bottom').hide();
        jQ('#main_menu_bg_l').css({'border-top': '36px solid #F4F4F4'});
    }
    ;
    if (window.m_w_width < 500) {
        jQ('.main_school_select #state :first').text('State');
        jQ('.main_school_select #school :first').text('University');
    } else {
        jQ('.main_school_select #state :first').text('Select a State');
        jQ('.main_school_select #school :first').text('Select your University');
    }
    ;

    //tabs
    if (jQ(window).width() <= 695 && (!jQ('#detail_tabs_line').hasClass('mobile') || jQ('#tab_content .tab').hasClass('mobile'))) {
        jQ('#detail_tabs_line, #tab_content .tab').addClass("mobile");
        jQ('#detail_tabs_line li').removeClass("active");
        jQ('#detail_tabs_line li').eq(0).addClass("active");
        jQ("#tab_content .tab").eq(0).addClass("active");
    }
    else if (jQ(window).width() > 695 && (!jQ('#detail_tabs_line').hasClass('mobile') || jQ('#tab_content .tab').hasClass('mobile'))) {
        jQ('#detail_tabs_line, #tab_content .tab').removeClass("mobile");
        jQ('#detail_tabs_line li').removeClass("active");
        jQ('#detail_tabs_line li').eq(1).addClass("active");
    }
});

